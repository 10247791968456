import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import "../css/lawyerdashboard.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import LawyerPersonalDetails from "../components/LawyerDashboard/LawyerPersonalDetails";
import LawyerDates from "../components/LawyerDashboard/LawyerDates";
import LawyerAccountSettings from "../components/LawyerDashboard/LawyerAccountSettings";
import LawyerPaymentSetting from "../components/LawyerDashboard/LawyerPaymentSetting";
import HelpAndSupport from "../components/LawyerDashboard/HelpAndSupport";
import image from "../assets/avatar.jpg";
import LawyerDatesDetail from "../components/LawyerDashboard/LawyerDatesDetail";
import LoadingBox from "../components/LoadingBox";
import { toast } from "react-toastify";
import { getError } from "../getError";
import Form from "react-bootstrap/Form";
import { Store } from "../Store";
import LawyerAbout from "../components/LawyerDashboard/LawyerAbout";
import LawyerPasswordChange from "../components/LawyerDashboard/LawyerPasswordChange";
import NavbarMenu from "./../components/NavbarMenu";
import {
  FaCalendarAlt,
  FaUser,
  FaInfoCircle,
  FaCogs,
  FaLock,
  FaMoneyBill,
  FaQuestionCircle,
  FaFileAlt,
} from "react-icons/fa";
import UploadedFilesListLawyer from "../components/LawyerDashboard/UploadedFilesListLawyer";

function LawyerDashboard() {
  const { state } = useContext(Store);
  const { lawyerInfo } = state;
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [picture, setPicture] = useState("");
  const [showDates, setShowDates] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const items = [
    { name: "Randevularım", icon: <FaCalendarAlt /> },
    { name: "Kişisel Bilgiler", icon: <FaUser /> },
    { name: "Hakkımda", icon: <FaInfoCircle /> },
    { name: "Dosyalarım", icon: <FaFileAlt /> },
    { name: "Hesap Ayarları", icon: <FaCogs /> },
    { name: "Sifre İşlemleri", icon: <FaLock /> },
    { name: "Ödeme Ayarları", icon: <FaMoneyBill /> },
    { name: "Yardım ve Destek", icon: <FaQuestionCircle /> },
  ];

  useEffect(() => {
    setPicture(lawyerInfo.picture ? lawyerInfo.picture : "");
  }, [lawyerInfo.picture]);

  const showComponent = (item) => {
    if (item === "Randevularım") {
      return <LawyerDates />;
    } else if (item === "Kişisel Bilgiler") {
      return <LawyerPersonalDetails />;
    } else if (item === "Hakkımda") {
      return <LawyerAbout />;
    } else if (item === "Dosyalarım") {
      return <UploadedFilesListLawyer />;
    } else if (item === "Hesap Ayarları") {
      return <LawyerAccountSettings />;
    } else if (item === "Ödeme Ayarları") {
      return "";
    } else if (item === "Yardım ve Destek") {
      // return <Navigate to="/frequentlyaskingquestion" />;
    } else if (item === "Sifre İşlemleri") {
      return <LawyerPasswordChange />;
    } else return <LawyerPersonalDetails picture={picture} />;
  };

  const handleDateClick = (date, index) => {
    setSelectedRow(index);
    setSelectedDate(date);
    setShowDates(true);
    setSelectedItem("Randevularım");
  };

  const showDatesDetails = (item) => {
    if (item === "Randevularım") {
      setShowDates(true);
    } else {
      setShowDates(false);
    }
  };

  const onItemClick = (item) => {
    setSelectedItem(item.name);
    setShowDates(false);
  };

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append("picture", file);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/api/upload/image-lawyer`,
        bodyFormData,
        {
          headers: { Authorization: `Bearer ${lawyerInfo.token}` },
        }
      );
      setPicture(data.filename);
      toast.success("Resim yüklendi. Güncellemek için kaydediniz.");
    } catch (err) {
      toast.error(getError(err));
    }
  };

  const savePicture = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URI}/api/lawyers/${lawyerInfo._id}`,
        {
          picture,
        },
        {
          headers: { Authorization: `Bearer ${lawyerInfo.token}` },
        }
      );
      setLoading(false);
      toast.success("Resminiz Kaydedildi.");
    } catch (error) {
      toast.error(getError(error));
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <NavbarMenu />
      <div className="lawyerdashbordmain d-flex justify-content-center mt-2">
        <div className="lawyerdashbord-container lawyerdashbord-container2">
          <div className="lawyerdashbord-leftcard lawyerdashbord-leftcard2">
            <div className="d-flex justify-content-center">
              <div className="lawyerdashboard-photo">
                <img
                  width="100px"
                  height="90px"
                  className="rounded-circle"
                  src={
                    picture
                      ? `${process.env.REACT_APP_BASE_URI}/uploads/image/${picture}`
                      : image
                  }
                  alt=""
                />
              </div>
            </div>

            <Form.Group className="mb-5 ms-5 fs-5" controlId="imageFile">
              <Form.Control type="file" onChange={uploadFileHandler} />
            </Form.Group>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "-10px",
              }}
            >
              <button
                className="lawyerdashboard-button-kaydet"
                onClick={savePicture}
              >
                {loading ? <LoadingBox /> : "Kaydet"}
              </button>
            </div>

            <div
              className="d-flex justify-content-center m-auto"
              style={{ width: "152px", fontSize: "14px" }}
            >
              {lawyerInfo.name} {lawyerInfo.surname}
            </div>
            <div className="border-bottom"></div>
            <div className="m-4 ">
              <ul>
                <li style={{ listStyle: "none", marginBottom: "20px" }}>
                  <b style={{ fontSize: "20px" }}>Hesabım </b>{" "}
                </li>
                {items.map((item, index) => (
                  <li
                    className="leftbox-li"
                    key={index}
                    onClick={() => onItemClick(item)}
                    style={{
                      fontWeight:
                        selectedItem === item.name ? "bold" : "normal",
                      listStyle: "none",
                      marginBottom: "5px",
                      fontSize: "15px",
                      borderRadius: "7px",
                      padding: "5px",
                      cursor: "pointer",
                      backgroundColor:
                        selectedItem === item.name ? "var(--main-color)" : "",
                      color: selectedItem === item.name ? "#fff" : "",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#f1f1f1";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor =
                        selectedItem === item.name ? "var(--main-color)" : "";
                      e.target.style.color =
                        selectedItem === item.name ? "#fff" : "";
                    }}
                  >
                    <span style={{ marginRight: "10px" }}>{item.icon}</span>
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="search-left-border"></div>
          <div className="lawyerdashbord-rightcard lawyerdashbord-rightcard2">
            {showComponent(selectedItem)}
          </div>
        </div>

        <div className="lawyerdashbord-datedetail-container">
          {showDates && selectedItem === "Randevularım" && (
            <LawyerDatesDetail date={selectedDate} />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default LawyerDashboard;
