import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Modal } from "react-bootstrap";

const PDFViewer = ({ pdfURL, isView, setIsView, filename=false }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return isView ? (
    <Modal
      className="pdfviewermodal"
      show={isView}
      onHide={() => setIsView(false)}
      animation={false}
    >
      <div
        style={{
          height: "750px",
          minWidth: "400px",
          maxWidth: "100%",
          padding: "12px",
        }}
      >
        <Worker
          workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}
        >
          <Viewer fileUrl={pdfURL} plugins={[defaultLayoutPluginInstance]} />
        </Worker>
      </div>
    </Modal>
  ) : "";
};

export default PDFViewer;
