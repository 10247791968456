import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { getError } from "../getError";
import LoadingBox from "../components/LoadingBox";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../css/RegisterPages.css";
import countries from "../pages/countries.json"; // Import countries from the JSON file

// Validation schema using Yup
const validationSchema = Yup.object({
  name: Yup.string().required("Ad alanı gereklidir."),
  surname: Yup.string().required("Soyad alanı gereklidir."),
  email: Yup.string()
    .email("Geçerli bir e-posta adresi girin.")
    .required("E-posta alanı gereklidir."),
  password: Yup.string().required("Şifre alanı gereklidir."),
  password2: Yup.string()
    .oneOf([Yup.ref("password"), null], "Şifre eşleşmiyor")
    .required("Şifre tekrar alanı gereklidir."),
  locationId: Yup.string().required("Ülke alanı gereklidir."),
  phone: Yup.string().required("Telefon alanı gereklidir."),
  branchId: Yup.string().required("Branş alanı gereklidir."),
  resume: Yup.mixed().required("Lütfen CV'nizi yükleyin."),
  memberAg: Yup.bool().oneOf([true], "Üyelik Sözleşmesi'ni kabul etmelisiniz."),
  infoText: Yup.bool().oneOf([true], "Aydınlatma Metni'ni kabul etmelisiniz."),
  perData: Yup.bool().oneOf(
    [true],
    "Kişisel Verilerin İşlenmesine İlişkin Açık Rıza Metni'ni kabul etmelisiniz."
  ),
});

const LawyerRegister = ({
  show,
  setShowLawyerLogin,
  setShowLawyerRegister,
}) => {
  const [locations, setLocations] = useState([]);
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleLogin = () => {
    setShowLawyerLogin(true);
    setShowLawyerRegister(false);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URI}/api/branchs`)
      .then((response) => {
        setBranches(response.data);
      })
      .catch((error) => {
        toast.error(getError(error));
      });
    axios
      .get(`${process.env.REACT_APP_BASE_URI}/api/locations`)
      .then((response) => {
        setLocations(response.data);
      })
      .catch((error) => {
        toast.error(getError(error));
      });
  }, []);

  const uploadResume = async (e) => {
    const formData = new FormData();
    formData.append("resume", e.target.files[0]);

    const uploadResponse = await axios.post(
      `${process.env.REACT_APP_BASE_URI}/api/upload/resume`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return uploadResponse.data.filename;
  };

  return (
    <Modal
      show={show}
      onHide={() => setShowLawyerRegister(false)}
      dialogClassName="modal-90w"
      animation={false}
      centered
    >
      <div className="registerlawyer">
        <div className="registerBaslık">
          <h1>Avukat Kayıt Ol</h1>
        </div>
        <Formik
          initialValues={{
            name: "",
            surname: "",
            email: "",
            password: "",
            password2: "",
            locationId: "",
            phone: "",
            branchId: "",
            memberAg: false,
            infoText: false,
            perData: false,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            setLoading(true);

            try {
              const registerData = {
                name: values.name,
                surname: values.surname,
                email: values.email,
                password: values.password,
                branchId: values.branchId,
                locationId: values.locationId,
                resume: values.resume,
                memberAg: values.memberAg,
                infoText: values.infoText,
                perData: values.perData,
                phone: {
                  countryCode: values.phoneRegion,
                  phoneNumber: values.phone,
                },
              };

              await axios.post(
                `${process.env.REACT_APP_BASE_URI}/api/lawyers/signup`,
                registerData
              );

              toast.success("AvukatLazim'a kayıt olma isteginiz tarafımıza iletilmiştir.");
              setShowLawyerRegister(false);
            } catch (error) {
              toast.error(getError(error));
              setErrors({ submit: getError(error) });
            } finally {
              setLoading(false);
              setSubmitting(false);
            }
          }}
        >
          {({ setFieldValue, isSubmitting, values, errors, handleChange }) => (
            <Form className="lawyerRegisterFormDiv">
              <div className="row" id="registerRowDiv">
                <div className="col-md-6">
                  <label className="registerLabel" htmlFor="name">
                    Ad*
                  </label>
                  <Field
                    name="name"
                    type="text"
                    className="registerFormControl"
                    placeholder="Ad*"
                  />
                  <ErrorMessage name="name" component="div" className="error" />
                </div>
                <div className="col-md-6">
                  <label className="registerLabel" htmlFor="surname">
                    Soyad*
                  </label>
                  <Field
                    name="surname"
                    type="text"
                    className="registerFormControl"
                    placeholder="Soyad"
                  />
                  <ErrorMessage
                    name="surname"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="col-md-6">
                  <label className="registerLabel" htmlFor="email">
                    E-posta*
                  </label>
                  <Field
                    name="email"
                    type="email"
                    className="registerFormControl"
                    placeholder="E-Posta*"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="col-md-6">
                  <label className="registerLabel" htmlFor="phoneNo">
                    Tel*
                  </label>
                  <div className="registerTelDiv d-flex">
                    <Field
                      as="select"
                      name="phoneRegion"
                      className="registerSelect"
                      id="inputGroupSelect02"
                      onChange={handleChange}
                      value={values.phoneRegion}
                    >
                      {countries.map((country) => (
                        <option key={country.code} value={country.code}>
                          {country.code}
                        </option>
                      ))}
                    </Field>
                    <Field
                      name="phone"
                      type="text"
                      className="registerFormControl"
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="registerLabel" htmlFor="locationId">
                    Ülke*
                  </label>
                  <Field
                    as="select"
                    name="locationId"
                    className="registerFormControl"
                    onChange={handleChange}
                  >
                    <option value="">Ülke Seç</option>
                    {locations &&
                      locations.map((location) => (
                        <option key={location._id} value={location._id}>
                          {location.country}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    name="locationId"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="col-md-6">
                  <label className="registerLabel" htmlFor="branch">
                    Branş*
                  </label>
                  <Field
                    as="select"
                    name="branchId"
                    className="registerBranchSelect"
                    onChange={handleChange}
                  >
                    <option defaultValue="all">Branş Seç</option>
                    {branches
                      ?.sort((a, b) => a.title.localeCompare(b.title))
                      .map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.title}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    name="branchId"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="col-md-6">
                  <label className="registerLabel" htmlFor="password">
                    Şifre*
                  </label>
                  <Field
                    name="password"
                    type="password"
                    className="registerFormControl"
                    placeholder="Şifre"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="col-md-6">
                  <label className="registerLabel" htmlFor="password2">
                    Şifre Tekrar*
                  </label>
                  <Field
                    name="password2"
                    type="password"
                    className="registerFormControl"
                    placeholder="Şifre"
                  />
                  <ErrorMessage
                    name="password2"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="col-md-6">
                  <label className="registerLabel" htmlFor="resume">
                    CV Yükle*
                  </label>
                  <input
                    name="resume"
                    type="file"
                    onChange={async (event) => {
                      const fileName = await uploadResume(event);
                      setFieldValue("resume", fileName);
                    }}
                    className="registerFormControl"
                  />
                  <ErrorMessage
                    name="resume"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="registerCheckBtn">
                <div>
                  <Field type="checkbox" name="memberAg" />
                  <label htmlFor="memberAg">
                    <span>Üyelik Sözleşmesi'ni</span> okudum ve kabul ediyorum.
                  </label>
                </div>
                <div>
                  <Field type="checkbox" name="infoText" />
                  <label htmlFor="infoText">
                    <span>Aydınlatma Metni'ni</span> okudum ve kabul ediyorum.
                  </label>
                </div>
                <div className="kisiselveriler">
                  <Field type="checkbox" name="perData" />
                  <label htmlFor="perData">
                    <span>
                      Kişisel Verilerin İşlenmesine İlişkin Açık Rıza Metni'ni
                    </span>{" "}
                    okudum ve kabul ediyorum.
                  </label>
                </div>
              </div>
              <div className="registerButtons">
                <button
                  className="registerbtn1"
                  type="button"
                  onClick={() => setShowLawyerRegister(false)}
                >
                  İptal Et
                </button>
                <button
                  className="registerbtn2"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {loading ? <LoadingBox /> : "Kayıt Ol"}
                </button>
              </div>
              <div className="registerNewDiv">
                <span>Üye misiniz? </span>
                <span>
                  <button
                    style={{
                      color: "var(--main-color)",
                      cursor: "pointer",
                      fontWeight: "700",
                    }}
                    className="registerNewBtn border-0"
                    onClick={handleLogin}
                  >
                    Giriş Yapın
                  </button>
                </span>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default LawyerRegister;
