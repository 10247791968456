import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../css/RegisterPages.css";
import axios from "axios";
import { toast } from "react-toastify";
import { getError } from "../getError";
import LoadingBox from "../components/LoadingBox";
import countries from "../pages/countries.json"; // Import countries from the JSON file

const UserRegister = ({ show, setShowUserRegister, setShowUserLogin }) => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneRegion, setPhoneRegion] = useState("+90");
  const [phoneNo, setPhoneNo] = useState("");
  const [password2, setPassword2] = useState("");
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("");

  const handleLogin = () => {
    setShowUserLogin(true);
    setShowUserRegister(false);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      toast.error("Şifre eşleşmiyor");
      return;
    }
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/api/users/signup`,
        {
          name,
          email,
          password,
          surname,
          phone: {
            countryCode: phoneRegion,
            phoneNumber: phoneNo,
          },
          address: {
            country,
          },
        }
      );
      setLoading(false);
      toast.success("Emailinize doğrulama linki gönderildi.");
      setShowUserRegister(false);
    } catch (error) {
      toast.error(getError(error));
      setLoading(false);
    }
  };

  // Sort countries by code
  const sortedCountries = countries.sort((a, b) =>
    a.code.localeCompare(b.code)
  );

  return (
    <Modal
      show={show}
      onHide={() => setShowUserRegister(false)}
      animation={false}
      centered
    >
      <div className="registeruser">
        <div className="registerBaslık">
          <h1>Danışan Kayıt Ol</h1>
        </div>
        <form className="UserRegisterFormDiv" onSubmit={submitHandler}>
          <div className="row" id="registerRowDiv">
            <div className="col-md-6">
              <label className="registerLabel" htmlFor="">
                Ad*
              </label>
              <input
                type="text"
                className="registerFormControl"
                value={name}
                placeholder="Ad*"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label className="registerLabel" htmlFor="">
                Soyad*
              </label>
              <input
                type="text"
                className="registerFormControl"
                value={surname}
                placeholder="Soyad"
                onChange={(e) => setSurname(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label className="registerLabel" htmlFor="">
                E-posta*
              </label>
              <input
                type="email"
                className="registerFormControl"
                placeholder="E-Posta*"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <div className="col-md-6">
              <label className="registerLabel" htmlFor="">
                Tel*
              </label>
              <div className="telDiv d-flex">
                <select
                  className="registerSelectuser"
                  id="inputGroupSelect01"
                  value={phoneRegion}
                  onChange={(e) => setPhoneRegion(e.target.value)}
                >
                  {sortedCountries.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.code}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  className="registerFormControl"
                  value={phoneNo}
                  placeholder="Telefon Numarası"
                  onChange={(e) => setPhoneNo(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <label className="registerLabel" htmlFor="">
                Ülke*
              </label>
              <select
                className="registerFormControl"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                <option value="">Ülke Seç</option>
                {sortedCountries
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-6">
              <label className="registerLabel" htmlFor="">
                Şifre*
              </label>
              <input
                type="password"
                className="registerFormControl"
                placeholder="Şifre"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
            <div className="col-md-6">
              <label className="registerLabel" htmlFor="">
                Şifre Tekrar*
              </label>
              <input
                type="password"
                className="registerFormControl"
                value={password2}
                placeholder="Şifre"
                onChange={(e) => setPassword2(e.target.value)}
              />
            </div>
          </div>
          <div className="registerButtons mt-4 mb-3">
            <button
              className="registerbtn1"
              onClick={() => setShowUserRegister(false)}
            >
              İptal Et
            </button>
            <button
              style={{
                backgroundColor: "var(--main-color)",
                color: "#f5f5f5",
                fontWeight: "normal",
                fontSize: "normal",
              }}
              className="registerbtn2"
              type="submit"
            >
              {loading ? <LoadingBox /> : "Kayıt ol"}
            </button>
          </div>
          <p>
            Zaten üye misin? Hemen{" "}
            <span
              style={{
                color: "var(--main-color)",
                cursor: "pointer",
                fontWeight: "700",
              }}
              onClick={() => handleLogin()}
            >
              Giriş Yap!
            </span>{" "}
          </p>
          <p>Doldurulması zorunlu alanlar *</p>
        </form>
      </div>
    </Modal>
  );
};

export default UserRegister;
