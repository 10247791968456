// FileListModal.js
import React, { useState } from "react";
import Modal from "react-modal";
import PDFViewer from "../PDFViewer";

Modal.setAppElement("#root"); // Adjust this if needed

const FileListModal = ({ isOpen, onRequestClose, files }) => {
  const [isView, setIsView] = useState(false);
  const [seletectedFile, setSelectedFile] = useState(null);

  const getFileList = (files, who) => {
    return (
      <>
        <ul style={{ paddingLeft: "40px",display:"flex",flexDirection:"column", alignItems:"start", gap:"2px" }}>
        <h4 style={{ paddingLeft: "5px" }}>{who}</h4>
          {files.map((file, index) => (
            <li
              key={index}
              className="btn"
              style={{ marginBottom: "3px", }}
              onClick={() => {
                setSelectedFile(file);
                setIsView(true);
              }}
            >
              {file}
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="File List"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: "600px",
        },
      }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <h2>Dosyalar</h2>
        <button onClick={onRequestClose} style={{ marginBottom: "10px" }}>
          Kapat
        </button>
      </div>
      {files.userFile.length === 0 && files.lawyerFile.length===0 ? (
        <p>Henüz dosya eklenmemiş.</p>
      ) : (
        <div style={{display:"flex", paddingLeft:"50px"}}>
          {files?.userFile.length > 0 &&
            getFileList(files.userFile, "Danışan Dosyaları")}
          {files?.lawyerFile.length > 0 &&
            getFileList(files.lawyerFile, "Avukat Dosyaları")}
        </div>
      )}
      <PDFViewer
        pdfURL={`${process.env.REACT_APP_BASE_URI}/uploads/pdf/${seletectedFile}`}
        isView={isView}
        setIsView={setIsView}
        filename={seletectedFile}
      />
    </Modal>
  );
};

export default FileListModal;
