import React, { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../assets/ssfooter-Photoroom.png";
import "../css/navbarmenu.css";
import { Link } from "react-router-dom";

const NavbarMenu = ({
  onBlogClick,
  onServicesClick,
  onAboutClick,
  onContactClick,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const navigateToHome = () => {
    window.location.href = "/";
  };

  return (
    <Navbar
      expand="lg"
      variant="light"
      sticky="top"
      style={{
        margin: 0,
        backgroundColor: "var(--main-color)",
      }}
    >
      <Container className="mainmenu d-flex flex-column align-items-center justify-content-center">
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleMenu} />
        <div>
          <Navbar.Brand
            className={`menulogo2 ${menuOpen ? "hidden" : ""}`}
            style={{ cursor: "pointer", display: "none" }}
            onClick={navigateToHome}
          >
            <img src={logo} alt="logo" style={{ height: "45px" }} />
          </Navbar.Brand>
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <div className="altı d-flex gap-5 align-items-center justify-content-between w-100">
            <div className=" sol3 d-flex align-items-center gap-5">
              <Nav className="me-auto">
                <Nav.Link
                  href="#"
                  className="menu-link"
                  onClick={onServicesClick}
                >
                  HİZMETLERİMİZ
                </Nav.Link>
                <Nav.Link href="#" className="menu-link" onClick={onAboutClick}>
                  HAKKIMIZDA
                </Nav.Link>
                <Nav.Link as={Link} to="/ekibimiz" className="menu-link">
                  EKİBİMİZ
                </Nav.Link>
              </Nav>
            </div>
            <div>
              <Navbar.Brand
                className="menulogo d-flex align-items-center"
                style={{ cursor: "pointer" }}
                onClick={navigateToHome}
              >
                <img src={logo} alt="logo" style={{ height: "45px" }} />
              </Navbar.Brand>
            </div>
            <div className=" menualt sag3 d-flex  align-items-center gap-5">
              <Nav>
                <Nav.Link href="#" onClick={onBlogClick} className="menu-link">
                  Blog
                </Nav.Link>
                <Nav.Link href="#" className="menu-link">
                  KARİYER FIRSATI
                </Nav.Link>
                <Nav.Link
                  href="#"
                  className="menu-link"
                  onClick={onContactClick}
                >
                  İLETİŞİM
                </Nav.Link>
              </Nav>
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarMenu;
