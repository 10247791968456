import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Store } from "../../Store";
import { toast } from "react-toastify";
import { getError } from "../../getError";
import PDFViewer from "../AdminDashboard/PDFViewer";

const UploadedFilesList = () => {
  const [userRequests, setUserRequests] = useState([]);
  const { state } = useContext(Store);
  const { lawyerInfo } = state;
  const [isView, setIsView] = useState(false);
  const [seletectedFile, setSelectedFile]= useState(null);


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URI}/api/requests/lawyer`, {
        headers: { Authorization: `Bearer ${lawyerInfo?.token}` },
      })
      .then((response) => {
        setUserRequests(response.data);
      })
      .catch((error) => {
        toast.error(getError(error));
      });
  }, [lawyerInfo?._id, lawyerInfo?.token]);

  
  const getFileList = (files, who) => {
    return (
      <>
        <h4 style={{ paddingLeft: "5px" }}>{who}</h4>
        <ul style={{ paddingLeft: "20px" }}>
          {files.map((file, index) => (
            <li key={index} className="btn" style={{ marginBottom: "3px" }} onClick={()=>{
              setSelectedFile(file);
              setIsView(true);
            }}>
              {file}
            </li>
          ))}
        </ul>
      </>
    );
  };


  return (
    <div style={{ padding: "10px", color: "#00242E" }}>
      <p
        style={{
          font: "normal normal bold 20px/24px SF Pro Text",
        }}
      >
        Yüklenen Dosyalar
      </p>
      <table
        className="uploadedFilesTable"
        style={{ width: "100%", marginTop: "25px" }}
      >
        <thead>
          <tr
            style={{
              font: "normal normal bold 16px/19px SF Pro Text",
              height: "25px",
            }}
          >
            <th
              style={{
                border: "1px solid #2d3657",
                width: "148px",
                height: "33px",
                opacity: "0.75",
                paddingLeft: "7px",
              }}
            >
              Danışan Adı
            </th>
            <th
              style={{
                border: "1px solid #2d3657",
                width: "145px",
                opacity: "0.75",
                paddingLeft: "7px",
              }}
            >
              Konu
            </th>
            <th
              style={{
                border: "1px solid #2d3657",
                width: "160px",
                opacity: "0.75",
                paddingLeft: "7px",
              }}
            >
              Tarih
            </th>
            <th
              style={{
                border: "1px solid #2d3657",
                width: "200px",
                opacity: "0.75",
                paddingLeft: "7px",
              }}
            >
              Dosyalar
            </th>
          </tr>
        </thead>
        <tbody style={{ font: "normal small normal 14px/16px SF Pro Text" }}>
          {userRequests.map((request, index) => (
            <tr
              key={request._id}
              style={{
                borderBottom: "1px solid #2d3657",
                paddingTop: "10px",
                paddingBottom: "10px",
                font: "normal normal normal 14px/16px SF Pro Text",
              }}
            >
              <td
                style={{
                  paddingTop: "7px",
                  paddingBottom: "7px",
                }}
              >
                {request?.userId?.name || "Avukat Atanmadı"}
              </td>
              <td
                style={{
                  paddingTop: "7px",
                  paddingBottom: "7px",
                }}
              >
                {request.description}
              </td>
              <td
                style={{
                  paddingTop: "7px",
                  paddingBottom: "7px",
                }}
              >
                {new Date(request.startDate).toLocaleDateString()} /{" "}
                {new Date(request.endDate).toLocaleDateString()}
              </td>
              <td
                style={{
                  paddingTop: "7px",
                  paddingBottom: "7px",
                }}
              >
                {request.lawyerFile.length > 0 &&
                  getFileList(request.userFile, "Danışan Dosyaları")}
                {request.lawyerFile.length > 0 &&
                  getFileList(request.lawyerFile, "Avukat Dosyaları")}
                {!(request.lawyerFile.length > 0) &&
                  !(request.lawyerFile.length > 0) &&
                  "Dosya Yiklenmedi"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <PDFViewer
        pdfURL={`${process.env.REACT_APP_BASE_URI}/uploads/pdf/${seletectedFile}`}
        isView={isView}
        setIsView={setIsView}
        filename={seletectedFile}
      />
    </div>
  );
};

export default UploadedFilesList;
